<template>
  <div class="background-wrapper" :class="componentSize">
    <!-- Only shows the animated background if the configuration allows it and if the window width is greater than 1024px (not mobile). -->
    <LavaLamp v-if="showAnimatedBackground && this.windowSize > 1024" />
    <SolidLavaLamp v-else class="solid-lavalamp" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { geometricBackgroundGetters } from '@/store/modules/geometricBackground/routines'
import LavaLamp from '@/components/LavaLamp.vue'
import SolidLavaLamp from '@/assets/images/config/sign-in-panel-background.svg?inline'
import config from '@/config/global'

export default {
  name: 'GeometricBackground',
  components: {
    LavaLamp,
    SolidLavaLamp
  },
  data() {
    return {
      showAnimatedBackground: config.whitelabel.signIn.geometricBackgroundAnimation || false,
      windowSize: window.outerWidth
    }
  },
  computed: {
    ...mapGetters({
      componentSize: geometricBackgroundGetters.COMPONENT_SIZE_ROUTINE
    })
  },
  methods: {
    // Sets the current window width.
    setCurrentWindowSize() {
      this.windowSize = window.outerWidth
    }
  },
  mounted() {
    window.addEventListener('resize', this.setCurrentWindowSize)
  },
  unmounted() {
    window.removeEventListener('resize', this.setCurrentWindowSize)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables.scss';

/* Possible sizes for the animated geometric background component. */

.one-quarter-width {
  width: 25vw;

  @media only screen and (max-width: $breakpoint-xlg) {
    width: 35vw;
  }

  @media only screen and (max-width: $breakpoint-md) {
    width: 0;
    visibility: hidden;
  }
}

.two-quarter-width {
  width: 50vw;

  @media only screen and (max-width: $breakpoint-md) {
    width: 0;
    visibility: hidden;
  }
}

.three-quarter-width {
  width: 75vw;

  @media only screen and (max-width: $breakpoint-md) {
    width: 0;
    visibility: hidden;
  }
}

.full-width {
  width: 100vw;
  z-index: -1;
}

.background-wrapper {
  transition: all $transition-normal ease-in-out;

  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
}

.solid-lavalamp {
  height: 100vh;
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;

  .lavalamp-primary {
    fill: $color-lava-lamp-primary;
  }
  .lavalamp-secondary {
    fill: $color-lava-lamp-secondary;
  }
}
</style>
